// Default theme
import '@splidejs/react-splide/css';

import React from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import styled from 'styled-components';

const testimonials = [
  {
    name: 'Steve Anderson',
    school: 'H.B. Lee Middle School',
    quote: 'Right out of the box it has good content, and engaging content for the students.',
  },
  {
    name: 'Abby Richardson',
    school: 'St Agatha Catholic School',
    quote:
      'Before Stile there was never a curriculum I really liked. I would pull pieces from here, pieces from there, and I felt like I was almost having to reinvent the wheel every year. I’ve never had labs work so well like Stile labs do.',
  },
  {
    name: 'Jim Anderson',
    school: 'H.B. Lee Middle School',
    quote:
      'Stile allows us to be flexible and change and adapt the materials so it reflects better what the students need.',
  },
  {
    name: 'Abby Richardson',
    school: 'St Agatha Catholic School',
    quote:
      'There are just so many different parts of the brain that Stile accesses with its question types and it all just builds together; it’s one curriculum but it’s reaching all of my kids that have these different learning styles.',
  },
  {
    name: 'Jenn Greenleaf',
    school: 'H.B. Lee Middle School',
    quote:
      'One of the things I like about Stile is that there is some cultural responsiveness. My middle school is very diverse and we have kids from all over the planet.',
  },
  {
    name: 'Elizabeth Neher',
    school: 'Lake Oswego School District',
    quote:
      'Every feature in it is 100% customizable and editable by the teacher. So I can take the great platform that’s already there, the base that they put together and make it work for the needs of my individual students.',
  },
  {
    name: 'Olivia Bailey',
    school: 'Astoria Middle School',
    quote:
      'We like how customizable it is as well as the immediate feedback and the formative assessments. It’s just really user friendly for teachers and it saves us a ton of time.',
  },
  {
    name: 'Christina De Anda',
    school: 'Armand Larive Middle School',
    quote:
      "We have a high demographic of newcomer students that English is their second or third language. I think this is really able to maximize their ability to understand science. We didn't always have translation last year.",
  },
  {
    name: 'Sam McLeod',
    school: 'Astoria Middle School',
    quote:
      'The other ones we looked at, the effort to get it going and implemented would have been a huge stress and transition... Whereas Stile, once Julianna got us the login you were going.',
  },
  {
    name: 'Mike Hall',
    school: 'Astoria Middle School',
    quote:
      'The learning targets are there, all the assessments are built in so it’s just crazy efficient. A lot of us like to develop our own curriculum but it’s really nice to have a foundation to build it from instead of feeling stressed.',
  },
  {
    name: 'Tavin Hamm',
    school: 'Armand Larive Middle School',
    quote:
      "I really think Stile has thought through everything that students and teachers are going to need. I think out of all the curricula that I've used I think it's very tech savvy and I think students are going to be more engaged.",
  },
  {
    name: 'Spencer Duggan',
    school: 'White Mountain Middle School',
    quote: 'With Stile, I’ll daydream less about quitting teaching.',
  },
];

function Testimonial({
  name,
  school,
  quote,
  ...props
}: { name: string; school: string; quote: string } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <p className="quote">{quote}</p>
      <div>
        <h6 className="h8">{name}</h6>
        <h6 className="h8">{school}</h6>
      </div>
    </div>
  );
}

const TestimonailsSection = styled.div`
  padding-bottom: 0;
  background-color: transparent;

  // break out from the container and go full viewport width
  margin-left: calc(50% - 50vw - 8px);
  width: 100vw;

  .splide {
    background-color: transparent;
    .splide__track {
      padding-bottom: 200px;
      background-color: transparent;
    }
    &:nth-child(2) {
      margin-top: -178px;
      .splide__track {
        padding-bottom: 100px;

        @media (max-width: 768px) {
          padding-bottom: 60px;
        }
      }
    }
  }

  &.mobile {
    display: none;
  }
  @media (max-width: 768px) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

const StyledTestimonial = styled<typeof Testimonial>(Testimonial)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 28px 25px 28px;
  align-items: flex-start;
  border-radius: 32px;
  border: 1px solid var(--stile-green-1);
  background: var(--white);

  height: 200px;
  width: 500px;

  @media (max-width: 768px) {
    width: 95%;
    height: 250px;
  }

  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.01), 0px 20px 20px 0px rgba(0, 0, 0, 0.01),
    0px 130px 78px 0px rgba(0, 0, 0, 0.03), 0px 58px 58px 0px rgba(0, 0, 0, 0.04),
    0px 14px 32px 0px rgba(0, 0, 0, 0.05);

  p,
  h6 {
    padding: 0%;
    margin: 0;
  }
`;

export default function Component() {
  const splideOptions = {
    type: 'loop', // Loop back to the beginning when reaching the end
    perPage: 3, // Number of items visible per page
    perMove: 1, // Move one item at a time
    rewind: false, // Rewind to start when the end is reached
    pagination: false, // Enable pagination dots
    gap: '28px', // Gap between slides
    width: '100%', // Width of the slider
    fixedWidth: '500px', // Fixed width of each slide
    fixedHeight: '200px', // Fixed height of each slide
    arrows: false, // Hide arrows
    trimSpace: true, // Trim space at the edges
    autoScroll: {
      pauseOnHover: true, // Do not pause scrolling when hovering over the carousel
      pauseOnFocus: true, // Do not pause scrolling when the carousel is focused
      rewind: true, // Rewind to start when the end is reached
      speed: 0.75, // Scrolling speed
    },
  };

  const sliderOptionsMobile = {
    ...splideOptions,
    perPage: 1,
    fixedWidth: '95%',
    fixedHeight: '250px',
    gap: '12px',
  };

  return (
    <>
      <TestimonailsSection className="desktop">
        <Splide options={splideOptions} extensions={{ AutoScroll }}>
          {testimonials.slice(0, 6).map((testimonial, index) => (
            <SplideSlide key={index}>
              <StyledTestimonial {...testimonial} />
            </SplideSlide>
          ))}
        </Splide>

        <Splide
          options={{
            ...splideOptions,
            autoScroll: {
              ...splideOptions.autoScroll,
              speed: splideOptions.autoScroll.speed * 1.33,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {testimonials.slice(6).map((testimonial, index) => (
            <SplideSlide key={index}>
              <StyledTestimonial {...testimonial} />
            </SplideSlide>
          ))}
        </Splide>
      </TestimonailsSection>
      <TestimonailsSection className="mobile">
        <Splide options={sliderOptionsMobile} extensions={{ AutoScroll }}>
          {testimonials.slice(0, 6).map((testimonial, index) => (
            <SplideSlide key={index}>
              <StyledTestimonial {...testimonial} />
            </SplideSlide>
          ))}
        </Splide>

        <Splide
          options={{
            ...sliderOptionsMobile,
            autoScroll: {
              ...sliderOptionsMobile.autoScroll,
              speed: sliderOptionsMobile.autoScroll.speed * 1.33,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {testimonials.slice(6).map((testimonial, index) => (
            <SplideSlide key={index}>
              <StyledTestimonial {...testimonial} />
            </SplideSlide>
          ))}
        </Splide>
      </TestimonailsSection>
    </>
  );
}
