import React, { ReactNode } from 'react';

import YouTubePlayer from 'react-player/youtube';
import styled from 'styled-components';

import { Grid } from 'stile-shared/src/components/2024/layout/Grid';

const StyledHearFromSection = styled.div<{ $backgroundColor?: string; $color?: string }>`
  background-color: var(--stile-grey-8);
  color: var(--white);

  dialog {
    background-color: var(--stile-grey-8);
    opacity: 0.9;
    padding: 0;
    width: 90vw;
    height: 90vh;
    ::backdrop {
      background-color: var(--stile-grey-8);
      opacity: 0.9;
    }
  }

  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .grid-layout {
      .left,
      .right {
        position: relative;
        .gatsby-image-wrapper {
          border-radius: 32px;
          border: 1px solid var(--stile-green-1);
        }
        .playButton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        h2 {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;
          @media (max-width: 768px) {
            margin-bottom: 20px;
          }
        }

        p {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;

          @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
      .left {
        grid-column: 1 / span 5;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
          padding-bottom: 30px;
        }
      }
      .right {
        grid-column: 7 / span 6;
        cursor: pointer;
        background-color: transparent;
        border: none;
        @media (max-width: 768px) {
          grid-column: 1 / span 12;
        }
      }
    }
  }
`;

type HearFromSectionProps = {
  heading: string;
  subheading: string;
  image: ReactNode;
  youtubeUrl: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function HearFromSection({
  heading,
  subheading,
  image,
  youtubeUrl,
}: HearFromSectionProps) {
  const refVideoDialogue = React.useRef<HTMLDialogElement | null>(null);
  const refVideoPlayer = React.useRef<YouTubePlayer | null>(null);

  React.useEffect(() => {
    if (refVideoDialogue.current) {
      refVideoDialogue.current.addEventListener('close', () => {
        refVideoPlayer.current?.getInternalPlayer().pauseVideo();
      });
      refVideoDialogue.current.addEventListener('click', (e) => {
        if (e.target === refVideoDialogue.current) {
          refVideoDialogue.current?.close();
        }
      });
    }
  });

  return (
    <StyledHearFromSection>
      <div className="content">
        <Grid>
          <div className="left">
            <h2>{heading}</h2>
            <div className="--text">
              <p>{subheading}</p>
            </div>
          </div>
          <button
            className="right"
            onClick={() => {
              refVideoDialogue?.current?.showModal();
              refVideoPlayer.current?.getInternalPlayer()?.playVideo();
            }}
          >
            {image}
            <svg
              className="playButton"
              xmlns="http://www.w3.org/2000/svg"
              width="78"
              height="78"
              viewBox="0 0 78 78"
              fill="none"
            >
              <circle opacity="0.5" cx="39" cy="39" r="39" fill="white" />
              <path d="M49 39L34 47.6603L34 30.3397L49 39Z" fill="#2B2B2B" />
            </svg>
          </button>
        </Grid>
        <dialog ref={refVideoDialogue}>
          <YouTubePlayer
            ref={refVideoPlayer}
            tabIndex={0}
            url={youtubeUrl}
            width="100%"
            height="100%"
            playing={false}
            playsinline={false}
            controls={true}
            rel={0}
          />
          <div id="player"></div>
        </dialog>
      </div>
    </StyledHearFromSection>
  );
}
